<template>
  <div class="r-cover">
    <div v-if="contentSections" class="r-cover flex-row md:flex bg-grey-500">
      <div
        :key="index"
        v-for="(section, index) in contentSections"
        :id="`section-${index}`"
        class="section relative w-full md:w-1/4 md:h-full min-h-96 flex justify-center items-center opacity-0"
        :class="{ 'z-10 md:z-auto': index === 0 }"
        :style="`background-color: ${section.colour}`"
      >
        <div v-if="section.featured_image" class="a-cover inset-0 overflow-hidden">
          <img
            class="section-img absolute right-0 bottom-0 w-auto max-w-none h-4/5 sm:h-2/5 opacity-0"
            :src="section.featured_image.url"
            :alt="section.featured_image.alt || `${section.title.rendered} section featured image`"
          />
        </div>
        <div class="relative">
          <Button
            class="section-btn relative opacity-0 bg-secondary py-3"
            :button="{
              button_text: section.title.rendered,
              button_link: section.is_subjects ? {} : `/explore/${section.slug}`,
              state_change: section.is_subjects ? subjectsOpen : null,
              button_label: section.is_subjects ? 'Open menu containing list of subjects' : `View ${section.slug} section`
            }"
            :aria-expanded="subjectsOpen ? 'true' : 'false'"
            @buttonClick="subjectsClick(section.is_subjects)"
          />
          <div
            v-if="section.is_subjects"
            :inert="!subjectsOpen"
            class="subjects absolute w-full bg-white transition-all duration-100 overflow-auto max-h-40vh z-10"
            :class="[subjectsOpen ? 'opacity-100 -translate-y-0' : 'opacity-0 translate-y-1.5']"
          >
            <ul>
              <li
                v-for="(subject, index) in subjects"
                :key="index"
              >
                <router-link
                  class="subjects__item block py-2 px-5 font-normal text-base"
                  :to="`/explore/subject/${subject.slug}`"
                  v-html="subject.title.rendered"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'
import Button from '@/components/buttons/Button.vue'

let tl = null

export default {
  name: 'Sections',

  components: {
    Button
  },

  data() {
    return {
      subjectsOpen: false
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.loadTimeline()
      tl.play()
    })
  },

  watch: {
    $route(from, to) {
      this.$nextTick(() => {
        if (from.meta.isSections && to.meta.isSections) {
          this.loadTimeline()
          tl.play()
        }
      })
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.animateOut(next)
  },

  beforeRouteLeave(to, from, next) {
    this.animateOut(next)
  },

  computed: {
    ...mapGetters(['sections', 'subjects']),
    contentSections() {
      return this.sections
    }
  },

  methods: {
    subjectsClick(isSubjects) {
      if (isSubjects) {
        if (this.subjectsOpen) {
          this.subjectsOpen = false
        } else {
          this.subjectsOpen = true
        }
      }
    },
    loadTimeline() {
      tl = gsap.timeline({
        paused: true
      })
      this.contentSections.forEach((item, index) => {
        const sectionDelay = (index * 0.1)
        const imgDelay = (index * 0.3)
        const btnDelay = (index * 0.3)
        tl.fromTo(`#section-${index}`, { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, delay: sectionDelay, duration: 0.6, ease: 'power1.inOut' }, 1)
        tl.fromTo(`#section-${index} .section-img`, { opacity: 0, x: 100 }, { opacity: 1, x: 0, delay: imgDelay, duration: 0.6, ease: 'power1.inOut' }, 2)
        tl.fromTo(`#section-${index} .section-btn`, { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: btnDelay, duration: 0.4, ease: 'power1.inOut' }, 3)
      })
    },
    animateOut(next) {
      this.subjectsOpen = false
      this.$store.commit('toggleMenu', false)
      tl.timeScale(2.5).reverse()
      tl.eventCallback('onReverseComplete', () => {
        next()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .section {
    @screen md {
      transition: width 0.6s ease-in-out;
      &:hover {
        width: 40%;
      }
    }
  }

  .subjects__item {
    &:hover,
    &:focus {
      padding-left: 15px;
      border-left: 5px solid var(--color-secondary);
      background-color: var(--color-grey-200);
      font-weight: bold;
    }
  }
</style>
